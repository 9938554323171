import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({ settings: state.settings }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  state: {
    settings: {
      app_name: "RS ID-GROW",
      form: [
        {
          model: "no_rm",
          label: "No. RM",
          type: "text",
          items: null,
        },
        {
          model: "no_resep",
          label: "No. Resep",
          type: "text",
          items: null,
        },
        {
          model: "nama",
          label: "Nama",
          type: "text",
          items: null,
        },
        {
          model: "kelamin",
          label: "Jenis Kelamin",
          type: "select",
          items: "Laki-laki,Perempuan",
        },
        {
          model: "tgl_lahir",
          label: "Tgl Lahir",
          type: "date",
          items: null,
        },
        {
          model: "alamat",
          label: "Alamat",
          type: "textarea",
          items: null,
        },
        {
          model: "unit",
          label: "Unit",
          type: "text",
          items: null,
        },
      ],
      obat: [
        "OBAT RACIKAN",
        "ABBOCATH 26 G",
        "ACARBOSE TAB / ECLID TAB",
        "ACETYLCYSTEINE",
        "ACLAM TAB",
        "ACRAN 150 TABLET",
        "ACRAN 300 TABLET",
        "ACRAN INJECTI",
        "ACTIFED 60ML SYRUP",
        "ACYCLOVIR 400 MG TAB",
        "ACYCLOVIR 5% ZALP",
        "ADALAT OROS 30 MG TAB",
        "AFAMED CAP",
        "ALA 600",
        "ALBAPURE 20% 50 ML VIAL",
        "ALBUMAN 20% 100 ML /ALBAPURE 20% 100 ML BTL",
        "ALBUMAN VIAL",
        "ALBUMIN BEHRING 20% X 100 ML",
        "ALBUMIN BEHRING 20% X 50ML",
        "ALCO DROP",
        "ALINAMIN F INJECTI",
        "ALKOHOL 70 % 1 LITER",
        "ALLOPURINOL 100 MG",
        "ALLOPURINOL 300 MG",
        "ALPRAZOLAM TAB 0.5 MG",
        "AMBROXOL SYR",
        "AMBROXOL TAB",
        "AMIKACIN 250 MG ",
        "AMINEFRON TABLET",
        "AMINOFILIN INJECTI",
        "AMINOFILIN TAB",
        "AMINOFLUID INF ",
        "AMINOFUSIN HEPAR",
        "AMINOFUSIN PAED 250ML INFUS",
        "AMINOLEBAN INF",
        "AMINOPLASMAL 10 %",
        "AMINOPLASMAL 5%",
        "AMINOSTERIL INFANT",
        "AMIODARONE INJ/ TIARYT INJ",
        "AMIPAREN",
        "AMITRIPTILIN TABLET",
        "AMLODIPIN 10 TAB",
        "AMLODIPIN 5MG TAB",
        "AMOXAN CAP 500 MG",
        "AMOXAN DROPS",
        "AMOXAN SYRUP",
        "AMOXICILLIN KAPLET",
        "AMOXICILLIN SYRUP",
        "AMPICILIN 1G INJ",
        "ANADEX KAPLET",
        "ANADEX SYR",
        "ANALSIK",
        "ANALSPEC CAPLET",
        "ANBACIM 500 KAPLET",
        "ANBACIM INJECTI",
        "ANCEFA 125 MG/5 ML SYRUP",
        "ANEMOLAT TAB",
        "ANFIX 100 CAPLET",
        "ANFIX SYR",
        "ANGINTRIZ MR",
        "ANTALGIN STRIP TABLET",
        "ANTASIDA SYRUP",
        "ANTASIDA TAB ",
        "ANTIHEMOROID SUPP",
        "ANTRAIN INJEKSI",
        "APIALYS DROP",
        "APIALYS SYR",
        "APRON BAG",
        "AQUA PRO INJECTI 25ML",
        "AQUA PRO INJEKSI 1000ML",
        "ARCAPEC 60 ML SYRUP",
        "ARIXTRA 2.5 MG INJ",
        "ARTRILOX 7,5MG",
        "ASAM MEFENAMAT/ASMEF 500 MG",
        "ASAM TRANEXAMAT INJ",
        "ASAM TRANEXAMAT TABLET",
        "ASAM VALPROAT / VALPROIC ACID",
        "ASEPTIC GEL / MEDITTOL",
        "ASEPTIC GEL 500 ML (BOTOL)",
        "ASERING INFUS",
        "ASKINA SOFT CLEAR 7,5CM X 5CM BBRAUN",
        "ASPILET TAB",
        "ASTATIN TAB",
        "ASTHIN FORCE 4 KAPL",
        "ASTHIN FORCE 6 TAB",
        "ASTHIN FORCE 8MG KAP",
        "ATORVASTATIN 20 MG TAB",
        "ATRACURIUM 25 INJ",
        "ATRACURIUM 50 INJ",
        "ATROPIN SULFAT INJECTI",
        "ATS 1500 UI / BIOSAT 1.5",
        "ATS 20000 UI / TERAPI",
        "AZITROMYCIN 500 MG",
        "BACTERIAL FILTER",
        "BACTRAZ INJ",
        "BAQUINOR FORTE",
        "BARZEPIN CAP 300",
        "BECOM - C",
        "BEROTEC SEMPROT",
        "BETADINE GARGLE ( KUMUR )",
        "BETAHISTIN 6MG",
        "BETASON-N CREAM",
        "BIONECT CREAM",
        "BIONECT GAUZE PADS",
        "BIONEURON INJ",
        "BIOSAVE 5 ML INJ",
        "BIOSYN 1",
        "BIOTHICOL CAPSUL 500 MG",
        "BIOTHICOL SYR",
        "BISOLVON SOLUTION",
        "BISOPROLOL TAB",
        "BISOVELL TAB",
        "BLEDSTOP INJ",
        "BLEDSTOP TAB",
        "BLOOD LANCET 26G",
        "BLOOD LANCET 28G",
        "BLOOD LANCET NOVATECH",
        "BLUE TIPS 500'S",
        "BOX ALKES SEDANG",
        "BOX OBAT KECIL",
        "BRACKET ABS ",
        "BRAINACT O-DIS CAP",
        "BRAXIDIN",
        "BREATHING CIRCUIT",
        "BREATHY NASAL SPRAY",
        "BRENARIS 100 ML SYR",
        "BRICASMA NEBUL",
        "BRILON USP 2-0 75OM 24MM",
        "BRILON USP 3-0 75OM 24MM",
        "BRILON USP 4-0 75OM 19MM",
        "BROADCED INJ",
        "BUAVITA 250 ML",
        "BUFECT FORTE SYR",
        "BUFECT SYR",
        "BUR DIAMOND EDENTA",
        "BURNAZIN CREAM /SULFADIAZINE CR",
        "BUSCOPAN INJ",
        "BUSCOPAN TABLET",
        "CAL 95 KAPLET",
        "CALBON TAB",
        "CALCIUM GLUCONAS",
        "CANDERIN 16MG (REGULER)",
        "CANDERIN 8 MG ( JKN )",
        "CANDERIN 8 MG TAB ( REGULER )",
        "CANDESARTAN 16MG / BLOPRESS 16MG",
        "CANDESARTAN TAB 8MG / BLOPRES 8 MG",
        "CANDOTENS 16MG",
        "CANDOTENS 8MG",
        "CAPROL INJ ",
        "CAPSINAT 500 MG",
        "CAPTOPRIL 12,5 MG",
        "CAPTOPRIL 25 MG",
        "CATAFLAM 50 MG TAB",
        "CATETER 10",
        "CATETER 12-18 ONEMED / ROMSON (JAMKESMAS)",
        "CATETER 12-18 RUSH(REGULER)",
        "CATETER 16 JKM/ URO ONE 16",
        "CATETER 20 TRIWAY",
        "CATETER 22",
        "CATETER 24 3 WAY",
        "CATETER 6 RUSH",
        "CATETER 8 RUSH",
        "CATETER STRAIGHT 32 CH",
        "CATETER SUCTION 14",
        "CATETER SUCTION NO. 16",
        "CATETER SUCTION NO.10 HITAM",
        "CATETER SUCTION NO.12 PUTIH",
        "CATETER SUCTION NO.6",
        "CATETER SUCTION NO.8",
        "CATETER TRIWAY 24 RUSH",
        "CATGUT CHROM 2/0 EP 3,5 ROLL (SMI)",
        "CATGUT CHROM 3/0 24MM 75OM",
        "CATGUT CHROMIC 0 100M",
        "CATGUT CHROMIC 1 75M",
        "CATGUT CHROMIC 1 HR 43 BBRAUN",
        "CATGUT CHROMIC 1 ROLL",
        "CATGUT CHROMIC 2 (SACHET)",
        "CATGUT CHROMIC 2 EP 6 ROLL (SMI)",
        "CATGUT CHROMIC 2/0 + JARUM",
        "CATGUT CHROMIC 3/0 + JARUM BBRAUN",
        "CATGUT CHROMIC 3/0 + JARUM ONEMED",
        "CATGUT CHROMIC 3/0 ONEMED",
        "CATGUT CHROMIC 3/0 SACHET DR. SELA",
        "CATGUT CHROMIC 4/0 ROLL",
        "CATGUT CHROMIC NO 0/0",
        "CATGUT PLAIN 0 ROLL",
        "CATGUT PLAIN 1/0",
        "CATGUT PLAIN 2/0 ROLL",
        "CATGUT PLAIN 2-0 100M",
        "CATGUT PLAIN 3/0 + JARUM BBRAUN",
        "CATGUT PLAIN 3/0 + JARUM ONEMED",
        "CATGUT PLAIN 3/0 GEA",
        "CATGUT PLAIN 3/0 ONEMED",
        "CATGUT PLAIN 3/0 SACHET DR. SELA",
        "CATGUT PLAIN USP 0, EP 4, 100M (SMI)",
        "CAVITON WHITE",
        "CEBACTAM INJ",
        "CEBEX CAP",
        "CECYL KAPSUL",
        "CEDOCARD 10 ML INJ",
        "CEFADROXIL 500",
        "CEFADROXIL SYR",
        "CEFAT 500 MG KAPSUL",
        "CEFAT DRY SYRUP",
        "CEFILA 100 TAB",
        "CEFILA SYRUP",
        "CEFIXIM KAPSUL",
        "CEFIXIM SYRUP",
        "CEFOBACTAM INJ",
        "CEFOPERAZONE INJ / CEPRAZ INJ",
        "CEFOPERAZONE SULBACTAM INJ",
        "CEFOTAXIM INJECTI",
        "CEFRIEX INJ",
        "CEFSPAN 100 MG",
        "CEFTAZIDIME 1 G INJ",
        "CEFTIZOXIME INJ 1 GR",
        "CEFTRIAXON INJ",
        "CEFXON INJ",
        "CELLCEPT 500MG TAB",
        "CENDO BERRY TAB",
        "CENDO CATARLENT TETES MATA",
        "CENDO EFRISEL ED",
        "CENDO FLOXA MD",
        "CENDO GENTAMYCIN SALEP MATA",
        "CENDO LFX MD",
        "CENDO LYTERS 15ML",
        "CENDO MYCOS SALEP MATA",
        "CENDO MYCOS TETES MATA",
        "CENDO MYDRIATYL 1 % 5 ML",
        "CENDO PANTOCAIN 0,5%",
        "CENDO POLIGRAN TETES",
        "CENDO POLYDEX 5ML",
        "CENDO POLYDEX MDS",
        "CENDO POLYNEL 5ML",
        "CENDO SILOXAN TETES MATA",
        "CENDO TIMOL 0.5 5 ML",
        "CENDO TOBROSON MD",
        "CENDO TROPIN",
        "CENDO VITROLENTA 0.6 ML MINIDOSE",
        "CENDO XITROL SALEP MATA",
        "CENDO XITROL TETES MATA",
        "CENTABIO GEL",
        "CEPEZET 100 TAB",
        "CEPEZET INJ",
        "CEPTIK 100 MG CAP",
        "CERCUL 250 INJ",
        "CERNEVIT INJ",
        "CERTOFIX PROTECT DUO",
        "CERTOFIX PROTECT TRIO",
        "CERVICAL COLLAR/PENYANGGA LEHER (L)",
        "CERVICAL COLLAR/PENYANGGA LEHER (M)",
        "CESTER TAB",
        "CETHIXIM INJ",
        "CETIRIZIN 5ML SYRUP",
        "CETIRIZINE KAPSUL",
        "CHLORAMPENICOL  CAPSUL 250",
        "CHLORAMPENICOL SYRUP",
        "CILOSTAZOL 100 MG /PLETAAL 100 MG",
        "CINOLON N CREAM",
        "CIPROFLOXACIN",
        "CIPROFLOXACIN INFUS",
        "CITICOLIN 1000MG TAB",
        "CITICOLIN 500MG TABLET",
        "CITICOLIN INJ",
        "CLANEKSI CAPSUL",
        "CLAROLID 500",
        "CLINDAMYCINE TAB 150 (ASKES)",
        "CLINDAMYCINE TAB 300 (ASKES)",
        "CLINIMIX INF",
        "CLOBAZAM 10MG TAB / CLOBIUM TAB",
        "CLOFRITIS TAB",
        "CLOPEDIN INJ/PETHIDIN HCL 2 ML AMPUL",
        "CLOPIDOGREL TABLET / PLACTA TAB",
        "CLOPRAMEL INJ",
        "CLORETHYL SPRAY",
        "CODEIN  20 MG TAB",
        "CODIKAF 10 MG",
        "CODIKAF 15 MG TAB",
        "CODIKAF 20MG TAB",
        "COLERGIS SYR 60ML",
        "COLERGIS TAB",
        "COLOSTOMI BAG 2 ONEMED",
        "COLOSTOMI BAG 3 ONEMED",
        "COLSANCETIN  INJECTIE",
        "COLSANCETIN 60ML SYRUP",
        "COLSANCETINE 250MG CAP",
        "COMAFUSIN HEPAR INF",
        "COMBIFLEX 1000 PERI",
        "COMBIPENEM INJ",
        "COMBIVENT NEBUL",
        "COMDIPIN 10 MG",
        "COMDIPIN 5MG",
        "CONCOR 2,5 MG TAB /  BISOPROLOL 2,5 MG TAB",
        "CONMED DUAL DISPERSIVE ELECTRODE",
        "COOLER THERMOMETER (VERIFY)",
        "CORDARONE INJ",
        "CORTISON INJEKSI",
        "COTRIMOXAZOLE  SYRUP",
        "COTRIMOXAZOLE 480 MG",
        "COVER GLASS",
        "CRIPSA TABLET",
        "CROME INJ",
        "C-SCRUB HANDWASING",
        "CTM",
        "CURCUMA TAB",
        "CYLOWAM 0,3 TETES MATA",
        "CYLOWAM 500 TAB",
        "CYSTONE TAB",
        "CYTOSTOL 200 MCG (REGULER)",
        "D 10 %",
        "D40 %",
        "D5 %",
        "D5 % 1/2 NS",
        "D5 % 1/4 NS",
        "D5 % 100ML",
        "DAFILON  3/0 DS 19",
        "DAFILON 2/0 DS 24",
        "DAFILON 3/0 DS 24",
        "DAFILON 4/0 DS 19",
        "DAFILON 5/0 DS 16",
        "DAFILON 6/0 DS 12",
        "DANSERA TAB",
        "DARYANTULLE/ FRAMYCETIN/ LOMATUELL",
        "DATAN FORTE TAB",
        "DECAIN INJ / BUPIVACAIN INJ",
        "DECULIN TAB 15 MG",
        "DECULIN TAB 30 MG",
        "DEGRADE TAB",
        "DEHIDRALYTE 200ML",
        "DEMESORB 1 37MM/75CM",
        "DEMESORB 2/0 75C 40MM",
        "DEMESORB 4/0 75C 22MM",
        "DEPAKENE SYRUP",
        "DEPAKOTE 250MG TAB",
        "DERMAFIX 6 X 7 CM",
        "DERMAFIX T 10X 25",
        "DERMALON 2-0",
        "DERMALON 3-0",
        "DERMALON 4-0",
        "DEXAMETASON INJECTI 5MG",
        "DEXAMETASON TAB",
        "DEXANTA SYRUP",
        "DEXKETOPROFEN 25 MG TAB",
        "DEXKETOPROFEN 50 MG INJ",
        "DEXTROMETHORPAN TABLET",
        "DIAFORMIN XR TABLET",
        "DIAZEPAM  TABLET 5 MG",
        "DIAZEPAM INJECTI",
        "DIAZEPAM TAB 2MG",
        "DIFLOXIN TAB",
        "DIGOXIN 0,25",
        "DILTIAZEM TABLET",
        "DIMENHIDRINAT TAB",
        "DIOVAN FCT 80MG",
        "DIPHENHIDRAMIN INJ/RECODRIL INJECTI",
        "DOBUTAMIN INJ / INODEX INJ",
        "DOMPERIDOME TABLET 10 MG",
        "DOMPERIDONE SYRUP 60ML",
        "DOPAMET TAB",
        "DOPAMIN 10 ML INJ",
        "DOVASK TABLET",
        "DUK STERIL",
        "DULCOLAC SUPP  DEWASA (REGULER)",
        "DULCOLAX DEWASA 10MGS (ASKES)",
        "DULCOLAX SUPP ANAK",
        "DULCOLAX TABLET",
        "DUPHASTON TAB",
        "DUVADILAN INJ",
        "DUVADILAN TABLET",
        "ECG PAPER",
        "ECG PAPER 210 MM X 30 M",
        "ECG PAPER 215 MM X 25 M",
        "ECG PAPER A4",
        "ECG PAPER ALFA 1000",
        "ECLID TABLET",
        "ECOSORB USP 1 75OM 48MM",
        "ECOSORB USP 2-0 75OM 26MM",
        "ECOSORB USP 5-0 75OM 24MM",
        "ECOSYN USP 1 90OM 40OM",
        "ECOSYN USP 3-0 75OM 24MM",
        "EFLAGEN 50 TAB",
        "EFLIN 60ML SYRUP",
        "EFOTAX INJEKSI",
        "ELECTRODA (ECG)",
        "ELGRAN 2MG TAB / ESTAZOLAM",
        "ELKANA SYRUP",
        "ELKANA TABLET",
        "EMERGENCY BOX",
        "ENERTON 500",
        "ENZYPLEX TAB",
        "EPEDRIN TABLET",
        "EPEXOL",
        "EPHEDRIN INJ",
        "EPIDOSIN INJ",
        "EPINEPRIN INJECTIE",
        "ERLAMYCETIN SALEP MATA",
        "ERLAMYCETIN TETES MATA",
        "ERLAMYCETIN TETES TELINGA",
        "ERYSANBE SYR",
        "ERYTROMYCIN 500 TAB",
        "ERYTROMYCIN SIRUP",
        "ERYTROMYCIN TAB",
        "ESOFERR INJ",
        "ESOMAX 40 INJ",
        "E-SOME",
        "ETAMBUTOL 500 MG",
        "ETHILON 10/0",
        "EUGENOL 20 GR BIODINAMIKA",
        "EUGENOL DETRY",
        "EUTHYROX 100MCG TAB",
        "E-ZOX INJ",
        "FARBIVENT INJ",
        "FARGOXIN INJ",
        "FARPRESIN INJ",
        "FASORBID INJ",
        "FASTOR",
        "FENOFIBRATE 100MG",
        "FENOFIBRATE 300MG",
        "FENTANYL INJ",
        "FEROSPAT TAB EFFERVESCENT",
        "FIBRION 1.500.000IU",
        "FLAMAR 25 MG",
        "FLAMAR 50 MG",
        "FLEET ENEMA",
        "FLEXAMINE CREAM 15 GR",
        "FLEXAMINE CREAM 30 GR",
        "FLUCONAZOLE 150MG TAB",
        "FLUCONAZOLE INFUS",
        "FLUNARIZIN 5 MG TAB",
        "FLUXAR 150MG TAB",
        "FOLAVIT 400MG",
        "FOLDA TAB",
        "FOLIC ACID",
        "FORINVEC 200 ML GARGLE",
        "FORMALIN 2,5 LITER",
        "FORMALIN TAB",
        "FORMUNO KAPLET",
        "FORMYCO CREAM",
        "FORTANEST INJ",
        "FUCOHELIX KAPLET",
        "FUCOHELIX SYR",
        "FUJI IX GP I-I",
        "FULADIC KRIM",
        "FURAMIN INJ",
        "FUROSEMID  INJECTI",
        "FUROSEMID TABLET",
        "FUTROLIT",
        "GABAPENTIN 300 MG/ ALPENTIN 300 MG",
        "GABBRYL 250 MG TAB",
        "GALIUM 5MG TAB",
        "GASTRIDIN INJECTI",
        "GASTROFER INJ 40 MG",
        "GASTRUL TAB",
        "GEEBIO",
        "GELAFUSAL INF",
        "GELAFUSINE 500 CC",
        "GELANG BABY",
        "GELISTASPON",
        "GELOFUSINE 500 CC BOTOL",
        "GEMFIBROZIL 300 MG",
        "GENOINT GENTAMYCIN SK",
        "GENTAMYCIN  SALEP MATA / GENOINT 0,3 %",
        "GENTAMYCIN  TETES MATA",
        "GENTAMYCIN AMPUL",
        "GENTAMYCIN SALF KULIT",
        "GETIDIN INJEKSI",
        "GITAS INJECTI",
        "GLERON 25MG",
        "GLIBENKLAMID",
        "GLICAB TAB",
        "GLICERIL GUAICOLAS (  GG  )",
        "GLIMEPIRID 3 MG TAB",
        "GLIMEPIRID 4MG",
        "GLIMEPIRIDE 1MG TABLET",
        "GLIMEPIRIDE 2 MG TAB",
        "GLIQUIDONE 30 MG TAB",
        "GLISERIN 1000ML",
        "GLOCEF INJ",
        "GLOMESON 4MG TAB",
        "GLOSIX INJ",
        "GLUCOBAY 50 MG",
        "GLUCODEX TABLET",
        "GLUCOLIN GLUKOSA",
        "GLUCOSAMINE 500",
        "GLURENORM 30MG",
        "GLUTROP TABLET",
        "GLUVAS 2MG TAB",
        "GOFLEX TAB",
        "GORON 25MG TAB",
        "GRAHABION TABLET (ASKES)",
        "GRANISETRON 1MG INJEKSI",
        "GRANISETRON 3MG INJEKSI",
        "GRATHEOS ",
        "GUARDIX SOL 5G",
        "GYPSONA 3",
        "GYPSONA 4",
        "GYPSONA 6 ",
        "H2O2 3 % 800 CC",
        "HALOTHANE",
        "HANDSCOEN GAMEX",
        "HANDSCOEN NON STERIL (PROTOS/SAFEGLOVE)",
        "HANDSCOEN PROTOS STERILE",
        "HANDSCOEN VASCO",
        "HANSAPLAST PLESTER STRIP",
        "HARNAL TAB",
        "HCT TABLET",
        "HEPA BALANCE",
        "HEPA Q ",
        "HEPABALANCE KAPL",
        "HEPAMAX 30 KAP",
        "HEPAMERZ GRANULATE",
        "HEPATIN TAB",
        "HERBALACTA CAP",
        "HERBESSER CD 100 CAPS",
        "HERBESSER INJ",
        "HESMIN FC CAP",
        "HIDROCORTISON SALF",
        "HIPAFIX 10X5/PLESTERIN 10X5 /PHARMAFIX 10X5",
        "HIPAFIX 5X5 / PLESTERIN 5X5",
        "HUMALOG KWIKPEN 3ML",
        "HUMULIN INSULIN",
        "HYDROMAL",
        "HYDROXYUREA 500",
        "HYOSINE N BUTHYL TAB",
        "HYOSINE N BUTYL INJ",
        "HYPOBHAC 100ML INJ",
        "HYPOBHAC 25ML INJ",
        "HYTROZ 1 MG TAB",
        "HYTROZ 2 MG TAB",
        "IBUPROFEN 200 MG",
        "IBUPROFEN 400 MG",
        "IBUPROFEN 60ML SYR",
        "IGLODEP TAB",
        "IMIPENEM CILASTATIN 1G INJ",
        "IMPUGAN INJ (FUROSEMID INJ)",
        "IMUNOS SYR",
        "IMUNOS TAB",
        "INBION KAPSUL",
        "INDUXIN 1 ML AMPUL",
        "INERSON SALF 15 GRAM (REGULER)",
        "INFIMYCIN 0.5 INJ",
        "INFLO 26 / STERACATH 26",
        "INFUSAN D10",
        "INFUSAN D5 ",
        "INFUSAN D5 1/2",
        "INFUSAN D5 1/4",
        "INFUSAN M-20",
        "INFUSAN NS ",
        "INFUSAN NS 100ML",
        "INFUSAN R + D",
        "INFUSAN RING AS",
        "INFUSAN RL ",
        "INFUSET MAKRO",
        "INFUSET MIKRO",
        "INOCID ID-IC INSTRUMEN DESINFE",
        "INTERHISTIN SYRUP",
        "INTERHISTIN TAB",
        "INTERLAC TAB",
        "INTERZINC SYR",
        "INTERZINC TAB",
        "INTIDROL INJ",
        "INTIDROL TABLET",
        "INTRAFIX ",
        "INTROCAN - MEDICUT - VASOFIX - INFLO",
        "INVOMIT 4 MG INJECTI",
        "IRTAN 150 (ASKES)",
        "IRTAN 300",
        "ISDN (ISOSORBID DINITRATE) 5 MG / FARSORBID 5 MG TAB",
        "ISOFLURAN 250ML / FORANE 250ML",
        "ISONIAZID 300 MG TAB",
        "ITZOL ",
        "JARUM 18G ONEMED",
        "JARUM 27G ONEMED",
        "JARUM GT 12",
        "JARUM GT 13",
        "JARUM JAHIT 10",
        "JARUM JAHIT 14",
        "JARUM/NEEDLE 26G ONEMED",
        "KACA MATA BABY EYE",
        "KAEN 3 A INFUS",
        "KAEN 3 B INFUS",
        "KAEN MG3",
        "KALBAMIN INF",
        "KALIPAR KAPL",
        "KALIUM DICLOFENAK ",
        "KAN TRACHEOSTOMY SIZE 7,8",
        "KANDISTATIN 12ML",
        "KAPAS 500 GR",
        "KAPSUL 0",
        "KAPSUL 00",
        "KAPSUL 1",
        "KAPSUL 2",
        "KASA GULUNG (VERBAN)",
        "KASA HIDROPHIL 40X80 ONE MED",
        "KASA STERIL (HEXA HUSADA 16X16)",
        "KCL 25 CC",
        "KENDARON TAB",
        "KEREN INJ",
        "KEREN TAB",
        "KERTAS PERKAMEN (OK)",
        "KERTAS PUYER",
        "KETESSE INJ ",
        "KETESSE TAB",
        "KETOKONAZOL CREAM",
        "KETOKONAZOLE 200 MG",
        "KETOPROFEN TAB",
        "KETOROLAC 10MG TAB",
        "KETOROLAC INJECTI 10 MG",
        "KETOROLAC INJECTI 30 MG ",
        "KETRICIN TAB",
        "KIDMIN 200ML",
        "KLORSEPT 17",
        "KOMPOSIT 3M Z350 XT",
        "KONIFLOX 5 ML TETES MATA",
        "KONILIFE LIVEGARD TAB",
        "KSR",
        "KTM INJ / KETAMIN INJ",
        "KUTOIN INJ (REGULER)",
        "KUTOIN KAPSUL",
        "LACIDOFIL KAPS",
        "LACIDOFIL SACHET",
        "LACTOPAIN 30MG INJ",
        "LACTOR 30 INJ",
        "LAMESON INJ",
        "LAMESON TABLET 4 MG",
        "LAMINARIA",
        "LANABAL INJ",
        "LANABAL TAB",
        "LANAKELOID CREAM",
        "LANSOPRAZOLE 30 MG",
        "LANZOGRA 30MG (LANSOPRAZOL)",
        "LAPIBAL 500",
        "LAPIBAL INJECTI",
        "LAPICEF 500 MG CAP",
        "LAPIMOX 500 MG TAB",
        "LAPIMOX SYR",
        "LAPIMUC TAB",
        "LAPISTAN 500MG TAB",
        "LAPIXIME INJ",
        "LASAL EXPECTORAN SYR",
        "LASAL INJ",
        "LASIX INJ",
        "LAXADINE 60ML SYR",
        "LAZ TAB",
        "L-BIO CAPSUL",
        "L-BIO SC",
        "L-CISIN TAB",
        "L-CORE",
        "LEPARSON TAB / LEVAZIDE TAB",
        "LEPTICA TABLET 150 MG",
        "LEPTICA TABLET 75 MG",
        "LESICHOL 300",
        "LETONAL 100 TABLET",
        "LETONAL 25 TABLET",
        "LEUKOPLAST 4,5 X 7,5",
        "LEVEMIR FLEXPEN",
        "LEVOFLOXACIN ",
        "LEVOFLOXACIN INFUS",
        "LEXA TAB",
        "LIDODEX INJECTI",
        "LIDOKAIN COMPOSITUM 2ML/ PEHACAIN INJ",
        "LIDOKAIN INJECTI",
        "LIFEZAR TAB",
        "LISINOPRIL 10MG / NOPERTEN 10MG TAB",
        "LISINOPRIL 5MG / NOPERTEN 5MG TAB",
        "LMA SUPREME SIZE 2",
        "LMA SUPREME SIZE 3",
        "LMA SUPREME SIZE 4",
        "LMA SUPREME SIZE 5",
        "LODIA TAB",
        "LODOMER INJ",
        "LORATADINE 10MG",
        "L-ORNITHIN-L-ASPARTATE 500MG (LOLA)",
        "LOVENOX 60 INJ",
        "LR'S STRETCH BANDAGE",
        "LUGOL LIQUID 100ML",
        "LUNEX TRANEXAMIC ACID 500 MG/ 5 ML INJEKSI",
        "LUPIN 5MG TAB",
        "MAGTRAL TAB",
        "MALTOFER",
        "MALTOFER FOL CHEW",
        "MALTOFER SYR",
        "MALTOVER TABLET",
        "MANITOL CAIRAN",
        "MARTOS 500 CC",
        "MASK NEBULA DEWASA",
        "MASK NEBULA PEDIATRIC",
        "MASKER",
        "MASKER ANASTESI (RESPIRATORY) CHILD",
        "MASKER ANASTESI (RESPIRATORY) INFANT",
        "MASKER ANASTESI (RESPIRATORY) LARGE ADULT",
        "MASKER ANASTESI (RESPIRATORY) MEDIUM ADULT",
        "MASKER ANASTESI (RESPIRATORY) NEONATUS",
        "MASKER ANASTESI (RESPIRATORY) SIZE 3",
        "MASKER ANASTESI (RESPIRATORY) SIZE 4",
        "MASKER NON REBREATING DWS",
        "MASKER NON REBRETING ANAK/PEDIATRIC",
        "MATOSIN INJ",
        "MAXIFLOW ANAK",
        "MAXIFLOW BAYI",
        "MAXIFLOW DEWASA",
        "MAXILAN INJ",
        "MAXPRO SYRUP",
        "MAXTER STERIL",
        "MAYO (40,70,80,90,100,110)",
        "MAYO NO. 0/5CM",
        "MAYO NO. 1/6CM",
        "MAYO NO.00/4CM",
        "MAYO NO.000/3CM",
        "MAYO NO.4/9CM",
        "MAYO NO.50",
        "MEBO SALP",
        "MECOBALAMIN 500MG KAP/ RINCOBAL TAB",
        "MECOBALAMINT INJ",
        "MEDERMA SALF",
        "MEDIFLEX CREAM",
        'MEDIGYPS 6\\"',
        "MEDISCRUB 5 LITER",
        "MEFINAL 500 MG",
        "MEFINTER 500 KAP",
        "MELIDOX TAB",
        "MELOXICAM TABLET 15 MG",
        "MELOXICAM TABLET 7,5 MG",
        "MEPTIN SWINGHALER",
        "MERISLON 12MG TAB",
        "MERISLON 6MG TAB",
        "MEROPENEM INJ",
        "MEROPENEM INJ (REGULER)",
        "MEROPROS 1 INJ",
        "MEROTIK 1 GR INJ",
        "MERSITROPIL 3 GRAM INJ",
        "MERSITROPIL INFUS",
        "MERTIGO TAB",
        "MESS NO.10",
        "MESS NO.13, 15, 20",
        "MESTAMOX KAPL",
        "METFORMIN 850 MG",
        "METFORMIN TABLET",
        "METHERINAL TABLET / METHYLERGOMETRIN TAB",
        "METHYCOBALT INJ",
        "METHYLERGOMETRINE INJEKSI",
        "METHYLPREDNISOLON 125 INJ",
        "METHYLPREDNISOLON 16 MG TAB",
        "METHYLPREDNISOLON 4 MG TAB",
        "METOCLOPRAMIDE INJ/ PIRALEN INJ/ METHOLON INJ",
        "METOCLOPRAMIDE TAB",
        "METRONIDAZOL 500MG",
        "METRONIDAZOL INFUS / DIAZOLE INF",
        "MEYLON 25 CC",
        "MGSO4 20 % 35 MG",
        "MGSO4 40 % 35 MG",
        "MICARDIS 80 MG TAB",
        "MICONAZOLE KRIM 2%",
        "MICRO BRUSH DOCHEM",
        "MICROLAX ENEMA",
        "MIDAZOLAM 5MG/1ML INJ",
        "MILOZ 15MG INJ",
        "MILOZ INJ (REGULER)",
        "MINIASPI 80MG TAB",
        "MINIJAW LOCK/SEGEL PLASTIK",
        "MISOPROSTOL 200 MCG",
        "MONOSCRUB CAIR",
        "MONOSYN 1 HR 48",
        "MONOSYN 2/0 HR 37",
        "MONOSYN 3/ 0 DS 24",
        "MONOSYN 4/ 0 DS 19",
        "MORPIN HCL1 ML AMPUL",
        "MOZUKU 50MG TAB",
        "MOZUKU SYR",
        "MST CONTINUS 10 MG",
        "MUCERA SYR",
        "MUCOHEXIN",
        "MUCOPECT DROPS",
        "MUCOPECT SYR",
        "MUCOSTA TAB",
        "MUCOTEIN SYR",
        "MUCUS EXTRACTOR JMS",
        "MUCYLIN 200 KAP",
        "MUSIN SYRUP",
        "MY JELLY",
        "MYOMAN TAB",
        "MYOMERGIN INJ",
        "MYONEP TAB",
        "MYOTONIC INJ",
        "MYOTONIC TABLET",
        "NAIRET INJ",
        "NAIRET TABLET",
        "NASOPHARYNGEAL AIRWAY 4.0",
        "NASOPHARYNGEAL AIRWAY 4.5",
        "NASOPHARYNGEAL AIRWAY 5.0",
        "NASOPHARYNGEAL AIRWAY 5.5",
        "NATRIUM DIKLOFENAK",
        "NATTO 10",
        "NEDLE SEPTOJEK 30 G",
        "NEEDLE CARPULE DOCHEM",
        "NEO K INJ",
        "NEO KAOLANA SYR ",
        "NEOSTIGMIN INJ",
        "NEPATIC CAPS",
        "NERFECO INJ",
        "NERFECO TABLET",
        "NESSIOL TAB",
        "NEULIN 1000MG INJ",
        "NEULIN PS TAB",
        "NEUROBAT  F INJECTI",
        "NEUROBION 5000 INJECTI",
        "NEUROBION 5000 TABLET",
        "NEURODEX TABLET",
        "NEUROSANBE  TABLET",
        "NEUROSANBE INJECTI",
        "NEW DIATAB",
        "NGT 10 (FEEDING TUBE)",
        "NGT 12 (FEEDING TUBE)",
        "NGT 14 (FEEDING TUBE)",
        "NGT 16 (FEEDING TUBE)",
        "NGT 18 (FEEDING TUBE)",
        "NGT 3,5 (FEEDING TUBE)",
        "NGT 5 (FEEDING TUBE)",
        "NGT 8 (FEEDING TUBE)",
        "NICAFER INJ",
        "NIFEDIPIN 10",
        "NOPANTIN 300MG",
        "NORAGES 60 ML SYRUP",
        "NORAGES INJ",
        "NOREPRINEPRIN / NEPI 4MG INJ",
        "NORFION INJ (NOREPINEPRIN INJ)",
        "NOTRIXUM 2.5ML INJ",
        "NOTRIXUM 50 INJ",
        "NOVALDO INJ",
        "NOVALGIN TABLET",
        "NOVERON INJ",
        "NOVO TWIST",
        "NOVOFINE NEEDLE",
        "NOVOMIX 30 FLEXPEN",
        "NOVORAPID FLEXPEN INSULIN 3 ML",
        "NTG INJ",
        "NUCRAL SYR / GITAFAT SYR",
        "NUPOVEL INJ",
        "NUTRI-B",
        "NUTRIBREAST CAP",
        "NUTRIFLAM NEO",
        "NUTRIFLAM TAB",
        "NUTRIMAMA 2",
        "NUTRIMAMA 3",
        "NUZOLE TAB",
        "NYLON DACLON 2/0",
        "NYLON DACLON 3/0",
        "NYLON DACLON 4/0",
        "NYSTATIN DROP",
        "NYSTIN DROP",
        "OBH SYRUP",
        "OBJEK GLASS 7101",
        "OBUCORT SPRAY",
        "OCTID INJ",
        "OCUSON TABLET",
        "ODEX 0,5 LITER",
        "OFLOXACIN INF",
        "OMEPRAZOL 20MG TAB",
        "OMEPRAZOLE INJ / OMEVELL INJ",
        "OMNIFIX 50CC",
        "OMZ INJ",
        "ONDANE 4MG",
        "ONDANE 8MG INJ",
        "ONDANSETRON 4 INJ",
        "ONDANSETRON 4MG TAB",
        "ONDANSETRON 8 INJ",
        "ONDANSETRON 8MG TAB",
        "ONDAVELL 4MG INJ",
        "ONE CLEAN 1 LITER",
        "OPICEF 500 CAPS",
        "OPIGRAN 1MG INJ",
        "OPIGRAN 3 INJ",
        "OPILAX SYR",
        "OPINEURON KAPLET",
        "OPIXIME CAP",
        "OPIZOLAM 0.5 MG TAB",
        "OPIZOLAM 1 MG TAB",
        "OPM CAP",
        "OPSITE 25 X 10",
        "OPSITE 8 X 10",
        "OPTICOM TETES MATA",
        "OPTIHEALTH",
        "OPTIME 2/0",
        "OPTIME 3/0",
        "OPTIME R 2/ 0",
        "OPTIVA 20G",
        "OPTIVA 22G",
        "OPTIVA 24G",
        "OPTIXITROL TETES MATA",
        "ORALIT 200 CC",
        "ORASIC INJEKSI",
        "OSCAL CALCITRIOL 0,25 KAP",
        "OSSORAL 800 CAP",
        "OSTEOKOM KAPLET",
        "OSTOBON SACHET",
        "OSTOVELL CAPSUL",
        "OTOPAIN EAR DOPS",
        "OTSUTRAN 40%",
        "OTSUTRAN 70%",
        "OTSUTRANTS 500 CC",
        "OTTOPAN DROPS",
        "OTTOPAN SYRUP",
        "OTTOZOL INJ",
        "OXTERCID INJECTI 750 MG",
        "OXYGEN CONNECTOR 180 CM ADULT",
        "OXYGEN CONNECTOR 180 CM PEDIATRIC",
        "OXYLA",
        "OXYTETRACICLYN SM/ TERRAMYCIN SM",
        "OXYTETRACYCLIN SALEP KULIT",
        "OXYTOCIN AMPUL",
        "OZID INJ",
        "PAMOL SYRUP",
        "PAN AMIN G INF",
        "PANSO INJ",
        "PANTERA INJ",
        "PANTOPRAZOL 20 MG TAB",
        "PANTOPRAZOLE / PANVELL TAB /PEPZOL 40 MG",
        "PANTOPRAZOLE INJ",
        "PAPAVERIN INJECTI",
        "PAPERPOIN DOCEM",
        "PARACETAMOL 500 MG STRIP",
        "PARACETAMOL DROP",
        "PARACETAMOL INFUS / FIORAMOL INFUS",
        "PARACETAMOL SYRUP",
        "PARACETAMOL TAB 500 MG KALENG/STRIP",
        "PCR TUBE 1.5 ML ( TABUNG CUP )",
        "PEDIMIN DROP",
        "PEN NEEDLE 32G X 4 MM",
        "PEPZOL 20MG TAB",
        "PEPZOL 40MG TAB",
        "PEPZOL INJ",
        "PERDIPINE INJ 10MG / NICARDIPIN INJ",
        "PERFUSOR TUBING WHITE 75CM",
        "PERIFIX 405 G16X3",
        "PERIFLUX SYRUP",
        "PESARIUM NO. 75",
        "PHADILON INJ",
        "PHALSY SYR",
        "PHAMINOV INJ",
        "PHAROLIT 200 CC",
        "PHENOBARBITAL 30MG TAB",
        "PHENOBARBITAL AMPUL 50 MG",
        "PHENYTOIN 100 KAPSUL",
        "PHENYTOIN INJ",
        "PHINEV INJ",
        "PICYN 1500MG INJ",
        "PICYN 750MG INJ",
        "PIOGLITAZONE 15 MG TAB",
        "PIOGLITAZONE 30MG TAB",
        "PIPET 500 UI",
        "PIPET KECIL",
        "PIRABRAIN 1 INJ",
        "PIRABRAIN 3 INJ",
        "PIRACETAM 1200 MG TAB",
        "PIRACETAM 400 MG",
        "PIRACETAM 800",
        "PIRACETAM INFUS 60 ML",
        "PIRACETAM INJECTI 1 GRAM",
        "PIRACETAM INJECTI 3 GRAM",
        "PIROXICAM 10 MG",
        "PIROXICAM 20 MG",
        "PISPOT",
        "PLANTACID SYR",
        "PLASBUMIN VIAL 25%",
        "PLASMINEX  INJECTI",
        "PLASMINEX TABLET",
        "POLIBAN 10",
        "POLIBAN 15",
        "POLIBAN 7,5",
        "POLICREPE 10",
        "POLICREPE 15",
        "POLICREPE 7,5",
        "POLIFIX 10X5",
        "POLIGYP 10",
        "POLIGYP 15",
        "POLIGYP 7,5",
        "POLINET 10X4",
        "POLYFLEX 26",
        "POLYSORB 1",
        "POLYSORB 2-0",
        "POLYSORB 3-0",
        "POLYSORB 4-0",
        "POLYWIN 18",
        "POLYWIN 20",
        "POLYWIN 24",
        "POLYWIN 26",
        "PONDEX SYR",
        "POSAFIT TABLET",
        "POSPARGIN 0.125 MG TABLET",
        "POSPARGIN INJ",
        "POSTOVIT TAB",
        "POT URINE 60 ML CONTAINER",
        "POT URINE CONTAINER 120 ML",
        "POT URINE NOVATECH",
        "POT ZALF",
        "POVIDON YODIDI/ BETADINE",
        "PPC INJECTI",
        "PPD 1,5ML",
        "PRADAXA 110 MG CAP",
        "PRADAXA 75MG TAB",
        "PREABOR",
        "PRECISION SOL. ADM.SET / TERUFUSION DISPOSABLE / BURET",
        "PRECOPAR 500 ML",
        "PREDNISON TAB/TRIFACORT TAB",
        "PREFORMED TRACHEAL TUBE(ETT BENGKOK) 3,5",
        "PREFORMED TRACHEAL TUBE(ETT BENGKOK) 4,0",
        "PREFORMED TRACHEAL TUBE(ETT BENGKOK) 5,0",
        "PREGABALIN 150MG TAB",
        "PREGABALIN 75MG /GLINOV 75MG TAB",
        "PREMILENE MESH 30 X 30",
        "PREMILENE MESH 5X10 CM",
        "PRIVE URICRAN",
        "PROBENID 500 MG TAB",
        "PROBIOSTIM CAPSULE",
        "PROCLOZAM",
        "PROGESIC SYR",
        "PROINFARK 10 MG INJ",
        "PROINFARK INJ 10 ML (ASKES)",
        "PROLIC 150 TAB",
        "PROLIC 300 TAB",
        "PROMUXOL DROP",
        "PRONALGES CR TABLET",
        "PRONALGES INJ",
        "PRONALGES SUPP",
        "PRONTOSAN 350ML",
        "PRONTOSAN 40ML",
        "PROPANOLOL 10 MG",
        "PROPANOLOL 40 MG",
        "PROPIRETIC SUPP 160 MG",
        "PROPIRETIC SUPP 80 MG",
        "PROPOFOL LIPURO 1%",
        "PROPYTHOURACIL 100 MG ( PTU )",
        "PRORENAL KAP",
        "PROSOGAN INJ",
        "PROSTIGMIN INJ",
        "PROTEXIN SACHET",
        "PROTOCIN INJ (OXYTOCIN)",
        "PROVAGIN OVULA",
        "PSIDII CAPSULE",
        "PSIDII SYRUP 60ML",
        "PULMICORT INHALER",
        "PULMICORT RESP",
        "PUMPITOR INJ",
        "PYRANTEL TAB",
        "PYRAZINAMIDE 500 MG TAB",
        "PYREX INFUS",
        "PZ / NACL INFUS",
        "PZ/NACL 100 ML INF",
        "Q 10 DS",
        "Q TEN 100",
        "Q TEN COM",
        "QUALICIN INJ / SUCCINYL CHOLIN",
        "QUIDEX INF",
        "RACLONID INJ",
        "RAIVAS INJ",
        "RAMIPRIL TAB",
        "RANITIDIN INJECTI",
        "RANITIDIN TABLET 150 MG",
        "RD 5 INFUS",
        "REBAMID TAB",
        "REBAMIPIDE TAB/REPIMIDE TAB",
        "RECOFOL INJ",
        "RECOLFAR TAB",
        "RECTAL TUBE 24",
        "RECTAL TUBE 28",
        "REDON DRAIN 14/12",
        "REDON DRAIN NEEDLE 14",
        "REDON DRAIN NO 14 450CC KOTAK",
        "REDON DRAIN NO 16 QUICKY",
        "REDON DRAIN NO 16 ROMSON",
        "REGIVELL 4 ML",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 2.5",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 3",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 3.5",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 4",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 5,0",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 5,5",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 6,0",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 6,5",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 7,0",
        "REINFORCED TRACHEAL TUBE(ETT NON KING) 7,5",
        "REJUVIT",
        "REMOPAIN 3%",
        "REMOVCHOL 20",
        "RENADINAC TABLET",
        "RENALYT",
        "RENOSAN 500 ML",
        "RENVOL 20GR",
        "RENXON INJ",
        "REQUIP PD 4MG",
        "RESUSITATOR BAYI",
        "REUCID TAB",
        "REVOLAN 1G INJ",
        "REVOLAN 3G INJ",
        "REXIDRON",
        "RHELAFEN FORTE SYR",
        "RHELAFEN SYR",
        "RHINOS JUNIOR SYR",
        "RHINOS NEO DROP",
        "RHINOS SR CAPSUL",
        "RIFAMPICIN 300 MG",
        "RIFAMPICIN 450 KAPSUL",
        "RIHEST 10MG",
        "RINDOBION 5000",
        "RING PESARM SIMACLUS 80",
        "RINGERFUNDIN INFUS",
        "RITES SYRUP 60 ML",
        "RITEZ FT TABLET",
        "RIVANOL",
        "RL 100ML",
        "RL BRAUN",
        "RL INFUS/ BAXTER/ OTSU/ WIDATRA",
        "ROCULAX INJ",
        "ROFIDEN INJ",
        "ROVADIN SYR",
        "ROXEMID INJ",
        "RYVEL DROP",
        "RYVELL SYRUP",
        "SABU",
        "SAFETY BOX 12,5 LITER",
        "SAFETY BOX 5 LITER",
        "SAFIL 1 HR 37S",
        "SAFIL 1 HR 40S",
        "SAFIL 2 HR 48",
        "SAFIL 2/0 HR 26",
        "SAFIL 2/0 HR 37",
        "SAFIL 3/0 HR 17",
        "SAFIL 3/0 HR 26",
        "SAFIL O",
        "SAFIL QUICK 1",
        "SAGESTAM CREAM",
        "SAGESTAM INJECTI",
        "SALBUTAMOL TAB 2 MG/ GRAFALIN 2MG TAB",
        "SALBUTAMOL TAB 4 MG",
        "SALIN 3% / PZ 3%",
        "SALIVA EJECTOR DOCHEM",
        "SALTICIN INJECTI",
        "SANADRYL DMP SYR",
        "SANADRYL EXP 60 CC",
        "SANBE HEST",
        "SANBE KIDS SYR",
        "SANBE PLEX DROP",
        "SANDEPRIL 50MG TAB",
        "SANGOFIX",
        "SANMAG SUSP",
        "SANMAG TAB",
        "SANMOL DROP",
        "SANMOL INFUS",
        "SANMOL SYR",
        "SANMOL TAB",
        "SANPRIMA SYRUP",
        "SANPRIMA TAB",
        "SANTAGESIK INJ",
        "SANTOCYN INJ (OXYTOCIN)",
        "SANVITA B SYRUP",
        "SCABIMITE 5% CREAM 10 G",
        "SCABIMITE 5% CREAM 30 G",
        "SCANDONEST",
        "SCOBUTRIN DRAGEE TAB",
        "SCOPAMIN TAB",
        "SEDACUM INJ",
        "SELANG OKSIGEN ANAK (NASAL)",
        "SELANG OKSIGEN BAYI (NASAL)",
        "SELANG OKSIGEN DEWASA (NASAL)",
        "SELANG OXYGEN NEONATUS (NASAL)",
        "SERETIDE DISKUS",
        "SEROLIN TAB",
        "SEVOFLURANE / SEVODEX 250 ML",
        "SHAROX 750 INJ",
        "SIBITAL INJ",
        "SIFROL 0.375",
        "SILK / ZIDE 3/0",
        "SILK / ZIDE ONEMED",
        "SILK 1 100M",
        "SILK 2-0 ,EP 3, 100M",
        "SILK BLACK",
        "SILK BLACK 2/0 OK",
        "SILK BLACK 3/0 OK",
        "SILK USP 2 , EP 5, 100 M",
        "SILKAM 1 HR 37",
        "SILKAM 2/0 DS 24",
        "SILKAM 2/0 HR 26",
        "SILKAM 3/0 HR 17",
        "SIMVASTATIN 10 TABLET",
        "SIMVASTATIN 20 MG TABLET",
        "SISTENOL TAB",
        "SMECTA",
        "SODERMIX KRIM 15 GR",
        "SOFRATUL",
        "SOFTBAN 3 INCH",
        "SOFTBAN 4 INCH",
        "SOFTBAN 6 INCH",
        "SOFTNOLIME",
        "SOLARE X COMPOSITUM",
        "SOLVINEX INJEKSI",
        "SOMATOSTATIN INJ",
        "SOMEROL INJ",
        "SPALK 10X5 CM",
        "SPALK 15X5 CM",
        "SPASMOMEN TAB",
        "SPINAL NEDLE 25",
        "SPINAL NEDLE 26",
        "SPINOCAN 25G",
        "SPINOCAN G26X3",
        "SPINOCAN G27",
        "SPINOCAN G29",
        "SPIRAMISIN 500 MG",
        "SPIRONOLACTON 100 MG",
        "SPIRONOLAKTON 25",
        "SPORETIK 100 CAPS",
        "SPUIT 1 CC",
        "SPUIT 10 CC",
        "SPUIT 10 CC OK/PD/JMI",
        "SPUIT 20 CC",
        "SPUIT 3 CC",
        "SPUIT 5 CC",
        "SPUIT 5 CC OK/PD/JMI",
        "SPUIT 50 CC",
        "SPUIT 50 CC CATHETER TIP",
        "SPUIT 50 CC PERFUSOR WHITE",
        "SPUIT 60 CC",
        "SPUTUM POT 7 CM 120 ML",
        "ST OBGYN STERIL GYNAECOLOG",
        "ST/ SARUNG TANGAN GYNAECOLOG / OBGYN",
        "STABIMED LARUTAN STERIL",
        "STARFOLAT TAB",
        "STARMUNO KAPL",
        "STARMUNO SYR 60ML",
        "STARQUIN INFUS",
        "STARXON INJ",
        "STATOR 10 TAB",
        "STERACATH 18, 20, 22, 24",
        "STERMITE SARUNG TANGAN",
        "STESOLID INJECTI",
        "STESOLID RECTAL",
        "STREPTASE 1.500.000 IU",
        "STREPTOMYCIN",
        "STROCAIN TABLET",
        "STRONGER NEO INJ (SNMC)",
        "SUCTION CONNECTING TUBE 24 ( 350 CM )",
        "SULFASALAZINE 500 MG KAPLET",
        "SUNSICOLIN",
        "SUPRAFENID / PROFECOM SUPP",
        "SUPRASORB A",
        "SUPRASORB F",
        "SUPRASORB G",
        "SURGICATGUT CHROMIC USP 2",
        "SURGICATGUT PLAIN 2-0 150CM",
        "SURGICRYL 1",
        "SURGICRYL 2/0",
        "SURGICRYL 3/0",
        "SUVESCO 20MG",
        "SYMBICORT",
        "TABLET TAMBAH DARAH",
        "TARONTAL INJ",
        "TARONTAL TAB",
        "TAXEF INJ",
        "TAXEGRAM 0,5 INJ",
        "TAXEGRAM INJ",
        "TEBOKAN TAB",
        "TEGADERM 6X7OM",
        "TEGADERM PAD 9X25OM",
        "TELMISARTAN 80 MG TAB",
        "TENSOCREPE 3 INCH",
        "TENSOCREPE 4 INCH/ PD CREPE 4'",
        "TENSOCREPE 6 INCH",
        "TEQUINOL 500MG TAB",
        "TERFACEF INJ",
        "TERREL 100ML (ISOFLURAN 100ML)",
        "TES KEHAMILAN ONEMED",
        "TETAGAM INJECTI",
        "TETAGAM P INJ",
        "TETRACYCLIN 250 MG KAPSUL",
        "TETRACYCLIN 500 /NOVABIOTIC 500 MG",
        "TETRASPAN INF",
        "TEVOX INFUS",
        "THEOBRON CAPSUL",
        "THERMOHYGROMETER",
        "THIAMPENICOL KAPSUL",
        "THIMELON 125 INJ",
        "THIMELON 4MG TAB",
        "THORACIC CATHETER STRAIGHT 24",
        "THYROZOL 10 MG TAB",
        "THYROZOL 5 MG TAB",
        "TKF",
        "TOFEDEX INJ",
        "TOMIT AMPUL",
        "TOPAZOL INJ",
        "TOPI OPERASI (NURSE CAP)",
        "TORAC CATETER 32",
        "TORASIC 10 INJ",
        "TORASIC 30 INJ",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 3,0",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 3,5",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 4,0",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 4,5",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 5,0",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 5,5",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 6,0",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 6,5",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 7,0",
        "TRACHEAL TUBE CUFFED (ETT CUFFED) 7,5",
        "TRACHEAL TUBE UNCUFFED (ETT UNCUFFED) 2",
        "TRACHEAL TUBE UNCUFFED (ETT UNCUFFED) 2,5",
        "TRACHEAL TUBE UNCUFFED (ETT UNCUFFED) 3",
        "TRACHEAL TUBE UNCUFFED (ETT UNCUFFED) 3.5",
        "TRACRIUM INJ",
        "TRAMADOL INJ",
        "TRAMADOL TABLET",
        "TRAMPARA KAPLET",
        "TRAMUS INJ",
        "TRANEXID INJ (ASKES)",
        "TRANEXID INJ (REGULER)",
        "TRANFUSI SET",
        "TRANSOFIX",
        "TREMENZA SIRUP",
        "TREMENZA TAB",
        "TRENSOCREPE BESAR",
        "TRENSOCREPE KECIL",
        "TRIBESTAN TABLET",
        "TRICODAZOLE INF",
        "TRICODAZOLE TAB",
        "TRICOFIX 10X8",
        "TRIDEX 27B INF",
        "TRIDEX INF 100",
        "TRIFEDRIN TAB",
        "TRIHEXYPHENIDYL TAB/ ARKINE 250 TAB",
        "TRILAC INJEKSI",
        "TRILAC TAB",
        "TRIWAY STOP COCK",
        "TRIWAY WITH EXTENSION",
        "TRIXON INJ",
        "TROGYL INFUS",
        "TROGYL SYR",
        "TROLIP 100MG",
        "TROMBOPOB GEL",
        "TROVENSIS 4 ML INJEKSI",
        "TROVENSIS 8 MG TAB",
        "TUZALOS",
        "UDOPA INJ",
        "ULCERANIN 150MG TAB",
        "ULCERANIN INJ",
        "ULSAFATE SYR 100ML / SUCRALFATE SYR",
        "ULSICRAL SYR",
        "ULTRAFIX 10X1 / MAMIFIX 10X1",
        "ULTRAFIX 5X1 /MAMIFIX 5X1 PLESTER/ PLESTERIN 5X1",
        "ULTRASONIC GEL 250 CC",
        "ULVICE",
        "UMARONE 2ML",
        "UMBILIKAL KLEM",
        "UNDERPAD",
        "UNIPOLAR SURGICAL ELECTRODE",
        "URDAFALK CAP",
        "URDEX 250MG CAP( URDAFALK )",
        "UREA ENZY",
        "URINAL PLASTIK LAKI-LAKI",
        "URINAL PLASTIK PEREMPUAN",
        "URINBAG ONE MED",
        "URINTER TABLET",
        "URISPAS 200 MG",
        "USG PAPER",
        "UTROGESTAN 200MG TAB",
        "VACLO TAB",
        "VACULAB 3ML EDTA UNGU 100'S",
        "VACULAB CITRAT 1.8 ML PP",
        "VACULAB GLASS PLAIN 3 ML (TUTUP MERAH)",
        "VAGISTIN OVULA",
        "VALAMIN INF",
        "VALDIMEX 5MG TAB",
        "VALDIMEX INJ (REGULER)",
        "VALEPTIK SYR",
        "VALESCO 80 MG TAB",
        "VALISANBE 2",
        "VALISANBE 5",
        "VALISANBE 5 INJ",
        "VALSARTAN 80 MG TAB",
        "VAPORIN TAB",
        "VARTEN 160 MG (VALSARTAN 160 MG)",
        "VARTEN 80 MG (VALSARTAN 80 MG)",
        "VASCON INJEKSI 4ML",
        "VASTIGO TAB",
        "VECTRINE CAP",
        "VECTRINE SYR",
        "VELATOL MIFAN",
        "VELCOX TAB 15 MG",
        "VELCOX TAB 7.5 MG",
        "VELUTINE INH",
        "VENFLON 20",
        "VENOCATH 18G",
        "VENOCATH 20G",
        "VENOFER INJ",
        "VENOFUNDIN INF",
        "VENOVER INJ",
        "VENTOLIN INHALER",
        "VENTOLIN NEBULES 2.5 MG",
        "VERCURE TAB",
        "VICCILIN 0.5 GR INJ",
        "VICCILIN-SX INJ",
        "VIGIROLL CATGUT CHROM, USP 0",
        "VIGIROLL CATGUT CHROM, USP 2",
        "VIGIROLL CATGUT CHROM, USP 3/0",
        "VIGIROLL CATGUT PLAIN USP 0 75M",
        "VIGIROLL CATGUT PLAIN USP 2 50M",
        "VIGIROLL SILK, USP 1",
        "VIGIROLL SILK, USP 2",
        "VIPALBUMIN CAP",
        "VIPALBUMIN SACHET",
        "VISANNE TAB",
        "VISEBAD PLUS SYR",
        "VIT B12 INJ",
        "VIT B6 TABLET (PYRIDOXINE TAB)",
        "VIT. B 1 TABLET (VITAMIN B1 TAB)",
        "VIT. B COMPLEK TABLET (VITAMIN B COMPLEK TABLET)",
        "VITAMIN B 1  INJECTI",
        "VITAMIN B12 TAB",
        "VITAMIN C INJECTI",
        "VITAMIN C TAB",
        "VITAMIN K INJECTI (PHYTOMENADION INJ)",
        "VITAMIN K TABLET",
        "VITAPEX",
        "VOMETA DROPS",
        "VOMETA SYR",
        "VOMETA TAB",
        "VOMETRAZ 4 INJ",
        "VOMETRAZ 8",
        "WARFARIN 1 MG TAB",
        "WATER ONE 20 LITER",
        "WIDA KDN (KAEN 3B INF)",
        "WIDAHES INF / FIMAHES INF",
        "WING NEEDLE",
        "WOMAN CHOICE STRIP",
        "XEPALIUM 5 MG TAB",
        "XEVOLAC 30 INJ",
        "YAFIX 100 TAB",
        "YELLOW TIP'S 1000'S",
        "ZAMEL SYRUP",
        "ZINC 20 MG TAB",
        "ZINCPRO SYR 60 ML",
        "ZINKID TAB",
        "ZITANID 2MG TAB",
        "ZOTER 400 TAB",
        "AMOXILIN",
        "PARACETAMOL",
        "LANSO",
        "BRAXIDIN",
        "L BIO CAPSUL",
        "RACIKAN",
        "CPG",
        "RINDOBION",
        "METFORMIN 500",
        "HYTOFAR",
        "KADIC",
        "EPERISON",
        "RANITIDIN",
        "LACTULOS SYR",
        "SIMVAS 20",
        "GLIME 2",
        "ACARBOSE 50",
        "BESANMAG",
        "ADALAT",
        "CANDE 16",
        "BISO 2,5",
        "GABA",
        "SPIRO 25",
        "CEFIXIME SYR",
        "ROFIDEN",
        "RACIKAN OPIMOX",
        "RACIKAN BAPIL",
        "FUROSEMID",
        "SPIRONOLAKTON",
        "DIOVAN 80",
        "PROFENAL",
        "GLUCOSAMIN 250",
        "NOVOMIX",
        "NOVORAPID",
        "ADALAT OROS",
        "GABAPENTIN 300",
        "SIMVASTATIN 20",
        "ALLOPURINOL 100",
        "PRAZOTEC",
        "AMLODIPIN 10",
        "CANDESARTAN 16",
        "HCT",
        "SUCRALFAT SYR",
        "CODITAM",
        "RHINOFER",
        "RAMIPRIL 5",
        "ASPILET",
        "MECOBALAMIN",
        "PROPANOLOL 10",
        "AMITRIPTYLIN",
        "GENTOINT",
        "ASAM MEFENAMAT",
        "LEXATRANS",
        "LANSOPRAZOLE",
        "GENTALEX",
        "CEFADROXIL SYR",
        "GLIME 1",
        "PIOGLITAZONE 15",
        "ALLOPURINOL 300",
        "ATORVASTATIN 20",
        "LEVEMIR",
        "FENOFIBRAT 300",
        "PIRACETAM 800",
        "CITICOLIN",
        "FLUNARIZINE",
        "CAVIPLEX",
        "CETIRIZINE",
        "B6",
        "TANAPPRESS 5",
        "HARNAL OCAS",
        "MICARDIS 80",
        "OPIPROL 2.5",
        "DIGOXIN",
        "CORALAN",
        "SIMARC",
        "TELMISARTAN 40",
        "MICONAZOLE",
        "RYZODEG",
        "CEFIXIME 200",
        "LEVOFLOXACIN 500",
        "NAC",
        "ISDN",
        "ALPRAZOLAM",
        "V-BLOCK",
        "BIOPREXUM 5",
        "CETIRIZINE SYR",
        "OBH SYR",
        "AZITHROMICYN",
        "RACIKAN NYERI",
        "LISINOPRIL 10",
        "CIPROFLOXACIN",
        "VIP ALBUMIN",
        "TRAMADOL",
        "OTTOPAN SYR",
        "OPIDIAR SYR",
        "GLIME 4",
        "MINIASPI",
        "NITRAL",
        "MELOX 15",
        "ASMEF",
        "URINTER",
        "IBUPROFEN",
        "MIERIN",
        "SALBU 2",
        "INTERHISTIN",
        "ATOR 40",
        "AMLODIPIN 5",
        "MEFINTER",
        "BLEDSTOP",
        "LOREMID",
        "CEFIXIME 100",
        "METRONIDAZOL",
        "TIMOL",
        "SANBE TEARS",
        "SANBE TEARS",
        "PROTAGENTA",
        "PROTAGENTA",
        "GLAUSETA",
        "KSR",
        "CODEIN 20",
        "LYTEERS",
        "VITROLENTA",
        "NONCORT",
        "EYEFRESH",
        "NADIC",
        "POLYDEX",
        "TOBRO",
        "RETIVIT PLUS",
        "TANAPRES 10",
        "TANAPRES 10",
        "METAMIZOLE",
        "PROVE 400",
        "VOLTADEX",
        "KALNEX",
        "VITAMIN C",
        "KALIUM DIKLOFENAK",
        "LEVOFLOXACIN 750",
        "LEVOFLOXACIN 750",
        "CALCIDO",
        "MELOXICAM 15",
        "ETORI 90",
        "VALPROID ACID SYR",
        "VIT B6",
        "BETAMETHASONE SK",
        "CETIRGI SYR",
        "RACIKAN LAMBUNG",
        "SANSULIN",
        "L-BIO CAPS",
        "ASAM FOLAT",
        "B COMPLEX",
        "HEPARMIN",
        "LANTUS",
        "APIDRA",
        "B KOMPLEK",
        "MELOXICAM 7,5",
        "OMEPRAZOLE",
        "CANDESARTAN 8",
        "DEXPROFEN",
        "GITAS PLUS",
        "CAVIPLEX CDEZ",
        "URIEF",
        "2 FDC OAT",
        "DOGESIC",
        "ZINC",
        "DOPEPSA SYR",
        "CLINDAMICIN",
        "DOMPERIDONE",
        "LAKTULOS SYR",
        "BISOPROLOL 5",
        "ACARBOSE 100",
        "CAPTOPRIL 50",
        "CAPTOPRIL 50",
        "VIT B1",
        "BECOMZET",
        "LISINOPRIL 5",
        "RACIKAN DOTRAMOL",
        "BISOPROLOL 2,5",
        "SPIRO 100",
        "METFORMIN 850",
        "EUTHYROX 100",
        "EUTHYROX 50",
        "THYROZOL 5",
        "ASTAR C",
        "VOMIGO 4",
        "PIOGLITAZONE 30",
        "INBUMIN",
        "TEQUINOL",
        "TEQUINOL",
        "KALK",
        "DIOVAN 160",
        "CAPTOPRIL 25",
        "ANTIMO",
        "CLOPIDOGREL",
        "MIOZIDIN",
        "CURCUMA FCT",
        "TAMBAH DARAH",
        "ONDAN 4",
        "ONDAN 4",
        "ANALSIK",
        "ANALSIK",
        "OREZINC SYR",
        "L-BIO SACH",
        "HERBESER",
        "FARNORMIN",
        "RHINOS",
        "ACETYLCTEIN",
        "RHINOFER CHEW",
        "ACETYLCYSTEIN",
        "FENITOIN",
        "DEPAKOTE",
        "CO AMOX",
        "CATARLENT",
        "BRALIFEX PLUS",
        "VOSAMA",
        "BERRY VISION",
        "ORALIT",
        "ANTASIDA SYR",
        "SALBUTAMOL 2MG",
        "TRIHEXY",
        "VOSERA",
        "YAFIX",
        "RANIVEL",
        "TANAPRES 5",
        "PARACETAMOL SYR",
        "APIALYS DROP",
        "CURCUMA SYR",
        "SUCRALFATE SYR",
        "PYREX",
        "CTM",
        "DEXKETO",
        "TEMISARTAN 80",
        "FOLAVIT",
        "FOLAVIT 400",
        "PREABOR",
        "METYLPRED 8",
        "RACIKAN CAPTOPRIL",
        "RAMIPRIL 10",
        "NATRIUM DICLOFENAK",
        "LOPERAMID",
        "OTTOGENTA",
        "B12",
        "FLAMIC",
        "ERYSANBE SYR",
        "METILPRED 8",
        "LANTUROL 400",
        "FLURIN",
        "DORIUM",
        "CANDEFAR 16",
        "CO-AMOX",
        "DIATAB",
        "PIRACETAM 400",
        "MODEXA",
        "H2O2 3%",
        "DESLORA",
        "TUZALOS",
        "TRIAMCINOLON",
        "ATORVASTATIN 10",
        "TIARYT",
        "UDCA",
        "AO",
        "MICARDIS 40",
        "CANDEFAR 8MG",
        "CODEIN 10",
        "NATTO",
        "CEPEZET",
      ],
      aturan: [
        "3 X 1",
        "2 X 1",
        "4 X 1",
        "3 X SEHARI",
        "2 X SEHARI",
        "4 X SEHARI",
        "3 X SEHARI SATU TABLET SESUDAH MAKAN",
        "3 X SEHARI SATU KAPSUL ",
        "4 X SEHARI SATU SETENGAH TABLET, SESUDAH MAKAN",
        "3   X   SEHARI   SETENGAH   TABLET ",
        "3   X   SEHARI   SATU   BUNGKUS",
        "3   X   SEHARI   SATU   SENDOK   OBAT",
        "2   X   SEHARI   OLES   TIPIS",
        "PAGI   SATU   TABLET",
        "SIANG   SATU   TABLET",
        "MALAM   SATU   TABLET",
        "PAGI  DAN  SIANG  SETENGAH  TABLET ",
        "PAGI  DAN  MALAM  SETENGAH  TABLET ",
        "SIANG  DAN  MALAM  SETENGAH  TABLET ",
        "3   X   SEHARI   10   UNIT ",
        "MALAM   10   UNIT",
        "2   X   SEMPROT",
        "6  X  TETES  SEHARI   MATA  KANAN  KIRI",
        "SERAHKAN   DOKTER",
        "TETESKAN  PADA  LIDAH  ATAU  MULUT",
        "3 X SATU TETES SEHARI MATA KANAN KIRI",
        "2 X SEHARI SATU TABLET SESUDAH MAKAN",
        "3X SEHARI SATU SENDOK SEBELUM MAKAN",
        "3X SEHARI DUA SENDOK SEBELUM MAKAN",
        "1 X SEHARI SATU TABLET SESUDAH MAKAN",
        "3 X SEHARI DUA TABLET SESUDAH MAKAN",
        "4 X SEHARI SATU TABLET SESUDAH MAKAN",
        "1 X SEHARI 1 TABLET - PAGI",
        "2 X SEHARI 1 TABLET",
        "1 X SEHARI 1 TABLET - MALAM",
        "2 X SEHARI 1/2 TABLET",
        "1 X SEHARI 10ML  - MALAM",
        "3 X SEHARI 1 TABLET",
        "1 X SEHARI 1 TABLET - SIANG",
        "3 X SEHARI 1 BUNGKUS",
        "1 X SEHARI 1 SUPPO",
        "3 X SEHARI 1 KAPSUL",
        "1 X SEHARI 1/2 TABLET - PAGI",
        "1 X SEHARI 1 TABLET, SIANG",
        "2 X SEHARI 3,75ML",
        "2 X SEHARI 1 KAPSUL",
        "3 X SEHARI 2 SENDOK TEH (10ML)",
        "OLESKAN 2 X SEHARI",
        "2 X SEHARI 3/4 SENDOK TEH (3.75ML)",
        "1 X SEHARI 4 TABLET",
        "1 X SEHARI 1/2 TABLET - MALAM",
        "3 X SEHARI 1 SENDOK TEH (5ML)",
        "PAGI - MALAM 10 IU",
        "2 X SEHARI 1 TETES",
        "4 X SEHARI 1 TETES",
        "6 X SEHARI 1 TETES",
        "MALAM 6 IU",
        "2 X SEHARI 3,2ML",
        "OLESKAN 3 X SEHARI",
        "2 X SEHARI 1/2 SENDOK TEH (2.5ML)",
        "MALAM 10 UNIT",
        "MALAM 16 IU",
        "3 X SEHARI 10 IU",
        "1 X SEHARI 1 KAPSUL  - MALAM",
        "2 X SEHARI 1 OLES",
        "1 X SEHARI 1/2 TABLET SIANG",
        "PAGI - MALAM 20 IU",
        "2 X SEHARI 1 SENDOK TAKAR",
        "1 X SEHARI 1/2 BUNGKUS",
        "1 X SEHARI 1 TABLET - SORE",
        "1 X SEHARI 1 TABLET KUNYAH",
        "6 X SEHARI 1 TETES MATA KANAN",
        "3 X SEHARI 2 TABLET",
        "3 X SEHARI 2 KAPSUL",
        "2 X SEHARI 1 TABLET- MUAL",
        "PAGI-MALAM 1/2 TAB",
        "4 X SEHARI 1 ML",
        "8 X SEHARI 1 TETES MATA KIRI",
        "8 X SEHARI 1 TETES MATA KANAN",
        "1 X SEHARI 1 KAPSUL  - PAGI",
        "2 X SEHARI 2 SPRAY HIDUNG KANAN KIRI",
        "CUCI TELINGA KIRI",
        "CUCI TELINGA KANAN",
        "1 X SEHARI 1 TAB , BILA PERLU",
      ],
      waktu: [
        "Sesudah Makan",
        "Sebelum Makan",
        "07:00 - 19:00",
        "07:00 - 13:00 - 19:00",
        "05.00 - 11.00 - 12.00 - 23.00",
        "05.00 - 09.00 - 13.00 - 17.00 - 21.00",
        "05.00 - 09.00 - 13.00 - 17.00 - 21.00 - 24.00",
        "PAGI 07:00",
        "SIANG 12:00",
        "SORE 16:00",
        "MALAM 19:00",
      ],
      catatan: [
        "OBAT INI TIDAK BOLEH DIULANG TANPA RESEP DOKTER",
        "ANTIBIOTIK , HARUS DIMINUM SAMPAI HABIS",
        "OBAT MENYEBABKAN SERING KENCING",
        "ANTIBIOTIK , HARUS DIMINUM SAMPAI HABIS",
      ],
      resep: {
        qty: "1",
        catatan: "Obat ini tidak boleh diberikan tanpa resep dokter",
        enable: "enable",
        udd_by: "waktu_split",
        enable_tipe: ["etiket_putih", "etiket_biru"],
        waktu_udd: [],
      },
      api: {
        enable: false,
        trigger: "no_rm",
        data: [],
      },
      apiFarmasi: {
        enable: false,
        trigger: "no_resep",
        data: ["no_rm", "no_resep"],
      },
      filter: {
        enable: false,
        trigger: "",
      },
      satuan: ["Tablet", "Kapsul", "Ampul", "Botol", "Bungkus"],
      defaultData: "pasien",
      print_server: true,
      gtm: true,
      custom: {
        enable: false,
        label: null,
        icon: "mdi-credit-card-edit",
      },
      antrian: false,
      gizi: false,
      multi_print: false,
      custom_print: false,
      exporter: false,
    },

    login: false,
    loader: false,
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value;
    },

    setting(state, payload) {
      state.settings[payload.key] = payload.value;
    },

    addData(state, payload) {
      if (!state.settings[payload.key]) {
        state.settings[payload.key] = [];
      }
      state.settings[payload.key].push(payload.value);
    },

    deleteData(state, payload) {
      state.settings[payload.key] = state.settings[payload.key].filter(
        (item) => item !== payload.value
      );
    },

    loading(state) {
      state.loader = !state.loader;
    },

    /* User */
    login(state) {
      state.login = true;
      localStorage.setItem("login", true);
    },

    logout(state) {
      state.login = false;
      localStorage.removeItem("login");
      // location.reload();
    },
  },

  getters: {
    app_name: (state) => {
      return state.settings.app_name;
    },

    settings: (state) => {
      return state.settings;
    },

    isLogged: (state) => !!state.login,
  },

  actions: {
    login({ commit }, credentials) {
      return this.axios
        .post("/login", credentials, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(({ data }) => {
          commit("setUserData", data);
        })
        .catch((err) => {
          return Promise.reject(err.response.data);
        });
    },

    async getSettings({ commit }) {
      try {
        const result = await this.axios.get(`/setting?$single`);
        commit("basic", { key: "settings", value: result.data.setting });
        return result.data.setting;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    restore_manually({ commit, state }, force) {
      let key = vuexLocalStorage.key;
      let storage = vuexLocalStorage.storage;
      if (!storage.getItem(key) || force) {
        commit("basic", { key: "settings", value: state.settings });

        fetch(window.rawUrl + "/assets/js/settings.js")
          .then((r) => r.json())
          .then((settings) => {
            // console.log(Object.entries(settings));
            if (settings.settings) {
              Object.entries(settings.settings).forEach(([key, value]) => {
                commit("setting", { key: key, value: value });
              });
              // commit("basic", { key: "settings", value: settings });
              if (
                Object.prototype.hasOwnProperty.call(
                  settings.settings,
                  "server_url"
                )
              )
                window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    },
  },

  plugins: [vuexLocalStorage.plugin],
});
